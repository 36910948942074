import cn from 'classnames'

import { Tile, TileProps } from '../../atoms/tile'
import css from './tile-grid.module.scss'

export type TileGridProps = {
  tiles: TileProps[]
  isReverse?: boolean
  overrideLinkText?: string
}

export const TileGrid = ({ tiles, isReverse = false, overrideLinkText }: TileGridProps) => {
  return (
    <div className={cn([css.container, isReverse && css.isReverse])}>
      {tiles.map((tile) => {
        return <Tile key={tile.label} {...tile} isTileGroup={true} linkText={overrideLinkText ?? tile.linkText} />
      })}
    </div>
  )
}
