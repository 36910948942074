import Tabs, { TabsProps } from 'components/tabs'

export type TileCarouselTabsProps = {
  children?: JSX.Element[]
  tabsProps?: TabsProps
  defaultCursor?: boolean
}

const TileCarouselTabs = ({ children = [], tabsProps, defaultCursor = false }: TileCarouselTabsProps) => {
  return (
    <Tabs headerAlign="center" {...tabsProps} defaultCursor={defaultCursor}>
      {children}
    </Tabs>
  )
}

export default TileCarouselTabs
