import { ReactNode } from 'react'

import { Autoplay, Mousewheel, Navigation, Pagination, SwiperOptions } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

export type CarouselProps = {
  slides: ReactNode[]
  slidesPerGroup?: number
  slidesPerView?: number | 'auto'
  spaceBetween?: number
  loop?: boolean
  navigation?: boolean
  pagination?: boolean
  autoplay?: {
    delay: number
    disableOnInteraction: boolean
  }
  className?: string
  mouseWheel?: boolean
  speed?: number
  effect?: SwiperOptions['effect']
  allowTouchMove?: boolean
}

export const Carousel = ({
  slides,
  slidesPerGroup = 1,
  slidesPerView,
  spaceBetween,
  loop,
  navigation = true,
  pagination = true,
  mouseWheel = false,
  autoplay,
  className,
  effect = 'slide',
  speed = 800,
  allowTouchMove = true,
}: CarouselProps) => {
  const loopable = Number(slidesPerView) * 2 < slides.length
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, Mousewheel]}
      navigation={navigation}
      pagination={pagination}
      slidesPerGroup={slidesPerGroup}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      loop={!!loop && loopable}
      autoplay={autoplay}
      className={className}
      mousewheel={!!mouseWheel && { releaseOnEdges: true }}
      effect={effect}
      speed={speed}
      allowTouchMove={allowTouchMove}
    >
      {slides.map((slideContent, index) => (
        <SwiperSlide key={index}>{slideContent}</SwiperSlide>
      ))}
    </Swiper>
  )
}
