// eslint-disable-next-line no-restricted-imports
import { default as NextLink } from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import cn from 'classnames'

import Button from 'components/button'
import { Notification } from 'components/notification'

import css from './index.module.scss'

type CardProps = {
  title: string
  variant?: 'default' | 'rounded' | 'text'
  cardKey: string
  className?: string
  isActive?: boolean
  panelId: string
  isDisabled?: boolean
  notifications?: number
  toggle: (key: string) => void
  isHyperlinked: boolean
  defaultCursor?: boolean
}

const TabCard = ({
  title,
  variant,
  cardKey,
  className,
  isActive,
  toggle,
  panelId,
  isDisabled,
  notifications = 0,
  isHyperlinked,
  defaultCursor = false,
  ...props
}: CardProps): JSX.Element => {
  const router = useRouter()
  const linkHrefObject = { query: { ...router.query, tab: cardKey } }

  const TabCardWrapper = isHyperlinked ? NextLink : 'div'

  let Header: React.ReactNode = null

  switch (variant) {
    case 'rounded':
      Header = (
        <Button
          label={title}
          className={cn(css['header--rounded'], defaultCursor && css.single)}
          style="rounded"
          forceActive={!!isActive}
          forceInactive={!isActive}
        />
      )
      break
    case 'text':
      Header = (
        <span className={cn(css['header--text'], css[isActive ? 'active' : 'inactive'], defaultCursor && css.single)}>
          {title}
        </span>
      )
      break
    case 'default':
    default:
      Header = (
        <div className={cn(css['header--default'], css[isActive ? 'active' : ''], defaultCursor && css.single)}>
          <span className={css.text}>{title}</span>
          {notifications !== 0 && <Notification numberOfNotifications={notifications} />}
        </div>
      )
      break
  }

  return (
    <TabCardWrapper className={css.container} href={linkHrefObject}>
      <li
        className={cn(isDisabled ? css.disabled : '', className)}
        onClick={() => (!isDisabled ? toggle(cardKey) : {})}
        role="tab"
        id={`card-${cardKey}`}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (!isDisabled && event.key === 'Enter') {
            toggle(cardKey)
          }
        }}
        tabIndex={isDisabled ? -1 : 0}
        aria-selected={isActive}
        aria-controls={panelId}
        {...props}
      >
        {Header}
      </li>
    </TabCardWrapper>
  )
}

export default TabCard
