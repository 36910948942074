import { Carousel } from 'components/carousel'
import { Slide } from 'components/carousel/slide'

import { Tile, TileProps } from '../../atoms/tile'
import css from './tile-carousel.module.scss'

export type TileCarouselProps = {
  data: TileProps[]
  overrideLinkText?: string
  slidesPerView?: number
}

const TileCarousel = ({ data, overrideLinkText }: TileCarouselProps) => {
  const slides = data.map((brand, i) => (
    <Slide key={brand.image}>
      <div style={{ paddingTop: i % 2 !== 0 ? '48px' : '0' }}>
        <Tile
          image={brand.image}
          key={brand.image}
          label={brand.label}
          linkText={overrideLinkText ?? brand.linkText}
          linkHref={brand.linkHref}
        />
      </div>
    </Slide>
  ))

  return (
    <div className={css.container}>
      <Carousel
        className="tile-swiper"
        pagination={false}
        navigation={false}
        slides={slides}
        spaceBetween={24}
        slidesPerView={'auto'}
        mouseWheel
      />
    </div>
  )
}

export default TileCarousel
