import cn from 'classnames'

import ImageComponent from 'components/image'
import Link from 'components/link'

import css from './tile.module.scss'

export type TileProps = {
  image: string
  imageTitle?: string
  imageTitlePosition?: 'top-left' | 'center'
  variant?: 'small' | 'big' | 'long'
  label?: string
  linkText?: string
  linkHref?: string
  isTileGroup?: boolean
  isKeypointTile?: boolean
}

export const Tile = ({
  image,
  imageTitle,
  variant = 'small',
  label,
  linkText,
  linkHref,
  imageTitlePosition = 'center',
  isTileGroup,
  isKeypointTile = false,
}: TileProps) => {
  return (
    <div className={css.container}>
      <div className={css.imageWrapper}>
        <Link href={linkHref} className={cn(css.imageLink, !linkHref && css.noPointer)}>
          <ImageComponent
            className={cn([
              css.image,
              css[`${variant}`],
              isTileGroup && css.tilegroup,
              isKeypointTile && css.keypointTile,
            ])}
            src={image}
            alt={imageTitle ?? 'image'}
            width={280}
            height={280}
            objectFit="cover"
          />
          {variant === 'big' && <span className={css.imageTitle}>{label}</span>}
        </Link>
        {!!imageTitle && <p className={cn([css.imageTitle, css[`imageTitle--${imageTitlePosition}`]])}>{imageTitle}</p>}
      </div>

      {!!label && variant === 'small' && <p className={css.name}>{label}</p>}
      {!!linkHref && variant === 'small' && (
        <Link className={css.link} href={linkHref}>
          {linkText ?? 'Browse All'}
        </Link>
      )}
    </div>
  )
}
