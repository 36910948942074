export type TabProps = {
  tabKey: string
  title?: string
  isDisabled?: boolean
  notifications?: number
  children?: React.ReactNode
}

const Tab = ({ tabKey, isDisabled = false, children, ...props }: TabProps): JSX.Element => (
  <div
    id={tabKey}
    key={tabKey}
    role="tabpanel"
    tabIndex={isDisabled ? -1 : 0}
    aria-labelledby={`card-${tabKey}`}
    {...props}
  >
    {children}
  </div>
)

export default Tab
