export const featuredTiles = [
  {
    image: '/images/lp/fp-1.jpg',
    linkHref: '/brand/united-kingdom-Helm-London',
  },
  {
    label: 'Officine Lutèce',
    image: '/images/lp/fp-2.jpg',
    linkHref: '/brand/france-Officine-Lutce',
  },
  {
    label: 'SMELLS LIKE SPELLS',
    image: '/images/lp/fp-3.jpg',
    linkHref: '/brand/lithuania-SMELLS-LIKE-SPELLS',
  },
  {
    label: 'hobo + co.',
    image: '/images/lp/fp-4.jpg',
    linkHref: '/brand/united-kingdom-hobo--co',
  },
  {
    label: 'BeCandle',
    image: '/images/lp/fp-5.jpg',
    linkHref: '/brand/hong-kong-BeCandle',
  },
]

export const newArrivalTiles = [
  {
    image: '/images/lp/new-1.jpg',
    linkHref: '/brand/usa-Black-and-Jane',
  },
  {
    label: 'Just Gentle',
    image: '/images/lp/new-2.jpg',
    linkHref: '/brand/thailand-Just-Gentle',
  },
  {
    label: 'BLUR LONDON',
    image: '/images/lp/new-3.jpg',
    linkHref: '/brand/united-kingdom-BLUR-LONDON',
  },
  {
    label: 'Coconut Matter',
    image: '/images/lp/new-4.jpg',
    linkHref: '/brand/hong-kong-Coconut-Matter',
  },
  {
    label: 'Oio Lab',
    image: '/images/lp/new-5.jpg',
    linkHref: '/brand/poland-Oio-Lab',
  },
]

export const bestSellerTiles = [
  {
    image: '/images/lp/best-1.jpg',
    linkHref: '/brand/united-kingdom-UpCircle-Beauty',
  },
  {
    label: 'Helm London',
    image: '/images/lp/best-2.jpg',
    linkHref: '/brand/united-kingdom-Helm-London',
  },
  {
    label: 'Clarity Blend Aromatherapy',
    image: '/images/lp/best-3.jpg',
    linkHref: '/brand/united-kingdom-Clarity-Blend-Aromatherapy',
  },
  {
    label: 'MALIN+GOETZ',
    image: '/images/lp/best-4.jpg',
    linkHref: '/brand/hong-kong-malingoetz',
  },
  {
    label: 'Tonik',
    image: '/images/lp/best-5.jpg',
    linkHref: '/brand/australia-Tonik',
  },
]

export const usBrands = [
  {
    label: 'Blind Barber',
    image: '/images/lp/us-1.jpg',
    linkHref: '/brand/usa-Blind-Barber',
  },
  {
    label: 'Bursera',
    image: '/images/lp/us-2.jpg',
    linkHref: '/brand/usa-Bursera',
  },
  {
    label: 'MAISON RUE',
    image: '/images/lp/us-3.jpg',
    linkHref: '/brand/usa-MAISON-RUE',
  },
  {
    label: 'Yuni Beauty',
    image: '/images/lp/us-4.jpg',
    linkHref: '/brand/usa-Yuni',
  },
  {
    label: 'ATELIER SAUCIER',
    image: '/images/lp/us-5.jpg',
    linkHref: '/brand/usa-ATELIER-SAUCIER',
  },
]

export const euBrands = [
  {
    label: 'Les Panacées',
    image: '/images/lp/eu-1.jpg',
    linkHref: '/brand/france-Les-Panaces',
  },
  {
    label: 'Avery Row',
    image: '/images/lp/eu-2.jpg',
    linkHref: '/brand/united-kingdom-Avery-Row',
  },
  {
    label: 'SMELLS LIKE SPELLS',
    image: '/images/lp/eu-3.jpg',
    linkHref: '/brand/lithuania-SMELLS-LIKE-SPELLS',
  },
  {
    label: '5mm Paper',
    image: '/images/lp/eu-4.jpg',
    linkHref: '/brand/portugal-5mm-Paper',
  },
  {
    label: 'ambustum',
    image: '/images/lp/eu-5.jpg',
    linkHref: '/brand/united-kingdom-ambustum',
  },
]

export const auBrands = [
  {
    label: 'Subtle Bodies',
    image: '/images/lp/au-1.jpg',
    linkHref: '/brand/australia-Subtle-Bodies',
  },
  {
    label: 'Tonik',
    image: '/images/lp/au-2.jpg',
    linkHref: '/brand/australia-Tonik',
  },
  {
    label: 'Mindful & Co Kids',
    image: '/images/lp/au-3.jpg',
    linkHref: '/brand/australia-Mindful--Co-Kids-',
  },
  {
    label: 'Keeko',
    image: '/images/lp/au-4.jpg',
    linkHref: '/brand/australia-Keeko',
  },
  {
    label: `Lil'Bit Better`,
    image: '/images/lp/au-5.jpg',
    linkHref: '/brand/australia-LilBit-Better',
  },
]

export const trendingBrands = [
  {
    label: 'Cosmydor',
    image: '/images/lp/trend-1.jpg',
    linkHref: '/brand/france-Cosmydor',
  },
  {
    label: 'Mindful & Co Kids',
    image: '/images/lp/trend-2.jpg',
    linkHref: '/brand/australia-Mindful--Co-Kids-',
  },
  {
    label: 'Klei Beauty',
    image: '/images/lp/trend-3.jpg',
    linkHref: '/brand/usa-Klei-Beauty',
  },
  {
    label: 'TITLEE',
    image: '/images/lp/trend-4.jpg',
    linkHref: '/brand/france-TITLEE',
  },
  {
    label: 'Pebblechild',
    image: '/images/lp/trend-5.jpg',
    linkHref: '/brand/united-kingdom-Pebblechild-UK-Ltd-',
  },
  {
    label: 'Huskee',
    image: '/images/lp/trend-6.jpg',
    linkHref: '/brand/hong-kong-Huskee',
  },
  {
    label: 'Uno Atelier',
    image: '/images/lp/trend-7.jpg',
    linkHref: '/brand/usa-Uno-Atelier',
  },
  {
    label: 'Midnight 00.00',
    image: '/images/lp/trend-8.jpg',
    linkHref: '/brand/spain-Midnight-0000',
  },
  {
    label: 'Daughters of Gaea',
    image: '/images/lp/trend-9.jpg',
    linkHref: '/brand/united-kingdom-Daughters-of-Gaea',
  },
  {
    label: 'to:from',
    image: '/images/lp/trend-10.jpg',
    linkHref: '/brand/spain-tofrom',
  },
  {
    label: 'Ana Miranda Ceramics',
    image: '/images/lp/trend-11.jpg',
    linkHref: '/brand/spain-Ana-Miranda-Ceramics',
  },
  {
    label: 'LOA SKIN',
    image: '/images/lp/trend-12.jpg',
    linkHref: '/brand/canada-LOA-SKIN',
  },
  {
    label: 'GISEL B.',
    image: '/images/lp/trend-13.jpg',
    linkHref: '/brand/france-GISEL-B-',
  },
]
