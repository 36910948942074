import { useRouter } from 'next/router'
import React, { ReactElement, useEffect, useState } from 'react'

import cn from 'classnames'

import TabCard from './card'
import { TabProps } from './tab'
import css from './tabs.module.scss'

export type TabsProps = {
  preselected?: string
  isHyperlinkedList?: boolean
  headerAlign?: 'left' | 'center'
  variant?: 'default' | 'rounded' | 'text'
  onTabChange?: (tabKey: string) => void
  onChangeActiveTab?: (tabKey: string) => void
  defaultCursor?: boolean
  children?: ReactElement<TabProps>[]
  tabContentClassName?: string
  tabNavClassName?: string
}

const Tabs = ({
  preselected,
  onTabChange,
  onChangeActiveTab,
  isHyperlinkedList = false,
  headerAlign = 'left',
  variant = 'default',
  defaultCursor = false,
  children = [],
  tabContentClassName,
  tabNavClassName,
  ...props
}: TabsProps): JSX.Element => {
  const router = useRouter()
  const {
    query: { tab: route },
  } = router

  const defaultTab = preselected ?? (children.length > 0 ? children[0].props.tabKey : '')
  const initialActiveTab = route ?? defaultTab

  const [activeTab, setActiveTab] = useState<string>(initialActiveTab as string)

  useEffect(() => {
    setActiveTab(initialActiveTab as string)
    onChangeActiveTab?.(initialActiveTab as string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActiveTab])

  const activateTab = (tab: string) => {
    if (activeTab !== tab && onTabChange) {
      onTabChange(tab)
    }
    setActiveTab(tab)
    onChangeActiveTab?.(tab)
  }

  return (
    <div className={css.container} {...props} data-test-id="tab-component">
      <div className={cn([css.navigation, tabNavClassName])}>
        <ul className={cn(css.cards, css[`cards--${variant}`], css[`cards--${headerAlign}`])} role="tablist">
          {children.map(
            (child: ReactElement<TabProps>): JSX.Element => (
              <TabCard
                key={child.props.tabKey}
                cardKey={child.props.tabKey}
                title={child.props.title ?? ''}
                variant={variant}
                toggle={activateTab}
                isActive={activeTab === child.props.tabKey}
                panelId={child.props.tabKey}
                isDisabled={child.props.isDisabled}
                notifications={child.props.notifications ?? 0}
                isHyperlinked={isHyperlinkedList}
                defaultCursor={defaultCursor}
              />
            )
          )}
        </ul>
      </div>
      <div className={cn([css.content, tabContentClassName, variant === 'default' ? '' : css.variantContent])}>
        {children.map((child: ReactElement<TabProps>): JSX.Element | string => {
          return (
            <div key={child.key} className={child.props.tabKey === activeTab ? css.active : css.hidden}>
              {child}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
