import cn from 'classnames'

import { Tile, TileProps } from 'components/atoms/tile'

import { TileGrid } from '../tile-grid'
import css from './tile-group.module.scss'

export type TileGroupProps = {
  tiles: TileProps[]
  isReverse?: boolean
  overrideBigTitle?: string
  overridelinkText?: string
}
export const TileGroup = ({ tiles, isReverse = false, overrideBigTitle, overridelinkText }: TileGroupProps) => {
  const bigTileProps = tiles[0]
  const smallTileProps = tiles.filter((_, i) => {
    if (i === 0) return false
    return true
  })

  return (
    <div className={cn([css.container, isReverse && css.isReverse])}>
      {isReverse ? (
        <>
          <TileGrid tiles={smallTileProps} overrideLinkText={overridelinkText} />
          <Tile
            image={bigTileProps.image}
            variant="big"
            linkHref={bigTileProps.linkHref}
            label={overrideBigTitle ?? bigTileProps.label}
          />
        </>
      ) : (
        <>
          <Tile
            image={bigTileProps.image}
            variant="big"
            linkHref={bigTileProps.linkHref}
            label={overrideBigTitle ?? bigTileProps.label}
          />
          <TileGrid tiles={smallTileProps} overrideLinkText={overridelinkText} />
        </>
      )}
    </div>
  )
}
