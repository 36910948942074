import { TileProps } from 'components/atoms/tile'
import Button from 'components/button'
import TileCarousel from 'components/molecules/tile-carousel'
import { TileGrid } from 'components/molecules/tile-grid'
import Tab from 'components/tabs/tab'

import TileCarouselTabs from '../../molecules/tile-carousel-tabs'
import css from './country-tile-carousel.module.scss'

type CountryTileTabProps = {
  carouselTiles: TileProps[]
  tabKey: string
  title: string
}

export type CountryTileCarouselProps = {
  tabs: CountryTileTabProps[]
  hasButton?: boolean
  overrideLinkText?: string
}

export const CountryTileCarousel = ({ tabs, hasButton = false, overrideLinkText }: CountryTileCarouselProps) => {
  const defaultCursor = tabs.length === 1

  return (
    <section className={css.container}>
      <div className={css.countryTabs}>
        <TileCarouselTabs tabsProps={{ variant: 'text' }} defaultCursor={defaultCursor}>
          {tabs.map((tab, i) => (
            <Tab tabKey={tab.tabKey} key={tab.tabKey} title={tab.title}>
              <div className={css.carousel}>
                <TileCarousel data={tab.carouselTiles} overrideLinkText={overrideLinkText} />
              </div>
              <div className={css.grid}>
                <TileGrid tiles={tab.carouselTiles} key={tab.tabKey} overrideLinkText={overrideLinkText} />
              </div>
            </Tab>
          ))}
        </TileCarouselTabs>
      </div>
      {!!hasButton && (
        <div>
          <Button className={css.button} label="Join Us" />
        </div>
      )}
    </section>
  )
}
