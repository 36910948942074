import { ReactNode } from 'react'

export type SlideProps = {
  children: ReactNode
  key: string
}

export const Slide = ({ children }: SlideProps) => {
  return <div>{children}</div>
}
